import * as mapStyles from './mapStyles.json'

class Map {
  constructor() {
    if (document.getElementsByClassName('map').length > 0) {
      this.mapContainer = document.getElementsByClassName('map')[0]
      if (window.googleapi === false) {
        window.loadJS(
          'https://maps.googleapis.com/maps/api/js?key=' +
            googleMapsKey +
            '&callback=window.map.init'
        )
        window.googleapi = true
      } else {
        this.init()
      }
    }
  }

  init() {
    const directionsDisplay = new google.maps.DirectionsRenderer()
    const bounds = new google.maps.LatLngBounds()
    const mapContainer = document.getElementsByClassName('map__container')[0]
    const markers = document.getElementsByClassName('marker')
    const markerIcon = document.getElementsByClassName('marker_icon')

    const svgMarker = {
      url: markerIcon[0].dataset.url,
      scale: 1,
      anchor: new google.maps.Point(15, 30)
    }
    const mapOptions = {
      zoom: parseInt(0),
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      styles: mapStyles
    }
    const map = new google.maps.Map(mapContainer, mapOptions)

    const infowindow = new google.maps.InfoWindow()

    for (let i = 0; i < markers.length; i++) {
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(markers[i].dataset.lat, markers[i].dataset.lng),
        map: map,
        icon: svgMarker
      })
      bounds.extend(marker.position)

      if (markers[i].getElementsByClassName('pop-marker').length > 0) {
        const pop = markers[i].getElementsByClassName('pop-marker')[0]
        let delegacionTitle = ''
        let delegacionInfo = ''
        let delegacionCat = ''

        if (pop.getElementsByClassName('information_title').length > 0) {
          delegacionTitle = pop.getElementsByClassName('information_title')[0].innerHTML
        }
        if (pop.getElementsByClassName('information_container').length > 0) {
          delegacionInfo =
            '<div class="information_container">' +
            pop.getElementsByClassName('information_container')[0].innerHTML +
            '</div>'
        }
        if (pop.getElementsByClassName('categories_container').length > 0) {
          delegacionCat =
            '<div class="categories_container">' +
            pop.getElementsByClassName('categories_container')[0].innerHTML +
            '</div>'
        }

        google.maps.event.addListener(marker, 'click', function () {
          var content = "<div class='infowindow__custom'>"
          content += '<h4>' + delegacionTitle + '</h4>'
          content += '</div>'
          content += delegacionInfo
          content += delegacionCat
          console.log(content)
          infowindow.setContent(content)
          infowindow.open(map, marker)
        })
      }
    }
    map.fitBounds(bounds)
    var zoom = map.getZoom()
    map.setZoom(zoom)

    map.addListener('click', function () {
      infowindow.close()
    })
    mapContainer.classList.remove('u-visually-hidden')
    directionsDisplay.setMap(map)

    // please note,
    // that IE11 now returns undefined again for window.chrome
    // and new Opera 30 outputs true for window.chrome
    // and new IE Edge outputs to true now for window.chrome
    // and if not iOS Chrome check
    // so use the below updated condition
    const isChromium = window.chrome
    const winNav = window.navigator
    const vendorName = winNav.vendor
    const isOpera = winNav.userAgent.indexOf('OPR') > -1
    const isIEedge = winNav.userAgent.indexOf('Edge') > -1
    const isIOSChrome = winNav.userAgent.match('CriOS')

    if (isIOSChrome) {
      // is Google Chrome on IOS
    } else if (
      (isChromium !== null &&
        isChromium !== undefined &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isIEedge === false) ||
      navigator.userAgent.indexOf('Safari') !== -1
    ) {
      // is Google Chrome or Safari
      document.addEventListener('DOMContentLoaded', function (event) {
        document.getElementById('panel').style.display = 'none'
      })
    } else {
      // not Google Chrome
    }
  }
}

export default Map
