class Lottie {
  constructor () {
    this.animatedElementsContainers = document.getElementsByClassName('js-lottie')
    if (this.animatedElementsContainers) {
      this.init()
    }
  }

  init () {
    Array.prototype.forEach.call(this.animatedElementsContainers, function (animatedElementContainer) {
      const animationPath = animatedElementContainer.getAttribute('data-animation-path')

      if (animationPath !== undefined && animationPath !== null) {
        window.loadJS('/wp-content/themes/tolsa/js/vendor/lottie.js', () => {
          window.lottie.loadAnimation({
            container: animatedElementContainer, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: animationPath, // the path to the animation json
            rendererSettings: {
              scaleMode: 'noScale'
            }
          })
        })
      }
    })
  }
}

export default Lottie
