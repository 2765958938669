class SDG {
  constructor () {
    this.sdg = document.getElementsByClassName('sustainable-development-goals')[0]
    if (this.sdg) {
      this.init()
    }
  }

  init () {
    const header = document.getElementById('header')
    const list = this.sdg
    const listItems = list.getElementsByClassName('js-sdg')

    if (listItems) {
      Array.prototype.forEach.call(listItems, function (listItem) {
        const listItemAnchor = listItem.querySelector('a')
        const listPopup = listItem.getElementsByClassName('sustainable-development-goals__popup')[0]
        const listItemPopupCloseButton = listItem.getElementsByClassName('sustainable-development-goals__close-popup')[0]

        if (listItemAnchor) {
          listItemAnchor.addEventListener('click', function (event) {
            event.preventDefault()

            // Disable scroll
            window.disableScroll.disableScroll() // Block scroll

            // Hide header
            header.classList.remove('fixed')

            // Open popup
            listPopup.style.display = 'initial'
          })
        }

        if (listItemPopupCloseButton) {
          listItemPopupCloseButton.addEventListener('click', function (event) {
            event.preventDefault()

            // Enable scroll
            window.disableScroll.enableScroll() // Unlock scroll

            // Close popup
            listPopup.style.display = 'none'
          })
        }
      })
    }
  }
}

export default SDG
