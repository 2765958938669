class Hero {
  constructor () {
    this.init()
  }

  init () {
    const video = document.querySelector('.hero__video, .hero--video-background__video')

    const toggleVideo = () => {
      if (video) {
        (video.src =
          window.innerWidth >= 768 ? video.getElementsByTagName('source')[0].dataset.src : '')
      }
    }

    window.addEventListener('resize', toggleVideo)
    toggleVideo()
  }
}

export default Hero
