class Pagination {
  constructor () {
    this.loadMoreButtons = document.getElementsByClassName('js-pagination-load-more')
    window.loading = false

    if (this.loadMoreButtons[0]) {
      this.init()
    }
  }

  init () {
    const pageNumbers = []
    const loadMorePosts = this.loadMorePosts
    let loadMoreButtonsCounter = 0

    Array.prototype.forEach.call(this.loadMoreButtons, function (loadMoreButton) {
      loadMoreButtonsCounter++
      pageNumbers[loadMoreButtonsCounter] = 0

      // Add a data attribute to identify each button
      loadMoreButton.parentElement.dataset.loadMoreButtonNumber = loadMoreButtonsCounter

      loadMoreButton.addEventListener('click', function (event) {
        const parent = this.parentElement
        const maxPages = parent.dataset.maxPages
        const titleLength = this.dataset.titleLength
        const columns = parent.dataset.columns
        const queryArgs = parent.dataset.queryArgs
        const loadMoreButtonNumber = parent.dataset.loadMoreButtonNumber

        event.preventDefault()

        if (window.loading === false) {
          // Lock load more button
          window.loading = true

          // Increase page number
          pageNumbers[loadMoreButtonNumber]++

          // Load posts
          loadMorePosts(pageNumbers[loadMoreButtonNumber], maxPages, titleLength, columns, queryArgs, parent)
        }
      })
    })
  }

  loadMorePosts (pageNumber, maxPages, titleLength, columns, queryArgs, loadMorePostsButton) {
    const request = window.ajaxReq()
    const url = encodeURI(scripts_ajax_variables.ajax_url) // eslint-disable-line
    const data = 'action=load_more_posts' + '&query=' + queryArgs + '&page=' + pageNumber + '&title-length=' + titleLength + '&columns=' + columns + '&nonce=' + scripts_ajax_variables.nonce // eslint-disable-line

    // AJAX request
    request.open('POST', url, true) // set true for async, false for sync request
    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
    request.send(data) // or null, if no parameters are passed

    // On success append data
    request.onreadystatechange = function () {
      if (request.readyState === 4 && request.status === 200) {
        try {
          // Append data
          loadMorePostsButton.insertAdjacentHTML('beforebegin', request.responseText)

          // Remove load more button if no more posts exist
          if ((parseInt(pageNumber) + 1) >= parseInt(maxPages)) {
            loadMorePostsButton.innerHTML = ''
          }
        } catch (error) {
          console.log(error)
        }
        // Unlock load more button
        setTimeout(() => {
          window.loading = false
        }, 1000)
      }
    }
  }
}

export default Pagination
