import LazyLoading from './lib/lazy-loading.js'
import Cta from './template-parts/components/cta.js'
import Filters from './template-parts/components/filters.js'
import Pagination from './template-parts/components/pagination.js'
import Hero from './template-parts/sections/hero.js'
import ContactForm from './template-parts/sections/contact-form.js'
import Facts from './template-parts/sections/facts.js'
import Accordion from './template-parts/sections/accordion.js'
import Map from './template-parts/sections/map.js'
import Slider from './template-parts/sections/slider.js'
import SDG from './template-parts/sections/sustainable-development-goals.js'
import Lottie from './lib/lottie.js'
import AnimationScenes from './template-parts/common/animationScenes.js'

export default class ModulesLoader {
  constructor (el = document) {
    this.el = el
  }

  loadModules () {
    window.lazyLoading = new LazyLoading()
    window.cta = new Cta()
    window.filters = new Filters()
    window.pagination = new Pagination()
    window.hero = new Hero()
    window.contactForm = new ContactForm()
    window.facts = new Facts()
    window.accordion = new Accordion()
    window.map = new Map()
    window.slider = new Slider()
    window.SDG = new SDG()
    window.lottieLoader = new Lottie()
    window.animationScenes = new AnimationScenes()
  }
}
