
import ScrollMagic from 'scrollmagic'

class AnimationScenes {
  constructor () {
    return this.animationScenes
  }

  animationScenes (scrollMagicController) {
    const animatableElements = [
      '.grid',
      '.grid__card',
      '.featured-content',
      '.hero',
      '.facts',
      '.featured-links',
      '.timeline',
      '.banner',
      '.text',
      '.post-content',
      '.highlighted-text',
      '.accordion',
      '.image',
      '.links',
      '.brands',
      '.downloads',
      '.logos-grid',
      '.map',
      '.customers-feedback',
      '.slider-gallery',
      '.text-cards',
      '.sustainable-development-goals',
      '.text-with-sidebar',
      '.video'
    ]
    // Set animation classes to all the elements that will use them.
    for (const el of Array.from(
      document.querySelectorAll(animatableElements.join(','))
    )) {
      new ScrollMagic.Scene({
        triggerElement: el,
        triggerHook: 0.75,
        reverse: false
      })
        .setClassToggle(el, 'animated')
        .addTo(scrollMagicController)
    }

    // Set animation behavior for elements with scroll-based parallax effect.
    const scrollAnimatableElements = [
      '.js-animatable-scroll'
    ]
    const scrollAnimatableHook = 0.7

    const scrollAnimatableProgressCallback = (evt, el) => {
      if (el.children.length > 0) {
        el.style.setProperty('overflow', 'hidden', 'important')
        el.children[0].style.setProperty('transition', 'transform 0.8s cubic-bezier(0.34, 1, 0.64, 1)')
        el.children[0].style.setProperty('transform', 'scale3d(' + (1 + (evt.progress / 4)) + ', ' + (1 + (evt.progress / 4)) + ', 1)', 'important')
      } else {
        const style = window.getComputedStyle(el, null)
        if (typeof style.transition === 'undefined') {
          el.style.setProperty('transition', 'background-size 0.8s cubic-bezier(0.34, 1, 0.64, 1)')
        } else if (style.transition.indexOf('background-size') === -1) {
          el.style.setProperty('transition', style.transition + ', background-size 0.8s cubic-bezier(0.34, 1, 0.64, 1)')
        }
        el.style.setProperty('background-size', (100 + (evt.progress * 25)) + '% auto', 'important')
      }
    }

    for (const el of Array.from(document.querySelectorAll(scrollAnimatableElements.join(',')))) {
      let waitingForAnimation = false

      const scene = new ScrollMagic.Scene({
        triggerElement: el,
        triggerHook: scrollAnimatableHook,
        duration: document.documentElement.clientHeight + (document.documentElement.clientHeight * scrollAnimatableHook)
      })
        .on('progress', (evt) => {
          if (!waitingForAnimation) {
            window.requestAnimationFrame(() => {
              scrollAnimatableProgressCallback(evt, el)
              waitingForAnimation = false
            })
            waitingForAnimation = true
          }
        })
        .addTo(scrollMagicController)

      // On window resize, update the duration.
      window.addEventListener('resize', window.throttle((evt) => {
        scene.duration(document.documentElement.clientHeight + (document.documentElement.clientHeight * scrollAnimatableHook))
      }, 500))
    }
  }
}

export default AnimationScenes
