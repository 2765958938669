import Pagination from '../components/pagination.js'

class Filters {
  constructor () {
    // Url params
    const urlParams = new URLSearchParams(window.location.search)
    this.selectedTags = urlParams.getAll('terms[]')

    // Elements
    this.tabsFilters = document.getElementsByClassName('js-tabs-filter')
    this.selectFilter = document.getElementsByClassName('js-select-filter')
    this.optionsFilter = document.getElementsByClassName('js-options-filter')
    this.tags = document.getElementsByClassName('js-filters')

    // Data
    this.selectedTab = document.getElementsByClassName('js-tabs-filter')[0] ? document.getElementsByClassName('js-tabs-filter')[0].dataset.term : null
    this.selectedOption = null

    if (this.tags[0]) {
      this.init()
    }
  }

  init () {
    const filterPosts = this.filterPosts
    const removeElementsByClass = this.removeElementsByClass
    const optionsFilter = this.optionsFilter
    const tags = this.tags
    const updateSelectedTags = this.updateSelectedTags
    const updateTagsClass = this.updateTagsClass
    const tabsFilters = this.tabsFilters
    let selectedOption = this.selectedOption
    let selectedTags = this.selectedTags
    let selectedTab = this.selectedTab

    // Set terms passed by urlTerms
    if (selectedTags.length > 0) {
      updateTagsClass(tags, selectedTags)
      filterPosts(
        tags[0].dataset.postType,
        selectedTab,
        selectedOption,
        selectedTags,
        tags[0].dataset.titleLength,
        tags[0].dataset.pagination,
        tags[0].dataset.viewAll,
        tags[0].dataset.maxPosts,
        tags[0].dataset.columns,
        tags[0],
        removeElementsByClass
      )
    }

    // Tabs taxonomies listeners
    Array.prototype.forEach.call(this.tabsFilters, function (tab) {
      tab.addEventListener('click', function (e) {
        e.preventDefault()
        for (let i = 0; i < tabsFilters.length; i++) {
          tabsFilters[i].classList.toggle('active')
          if (tabsFilters[i].classList.contains('active')) {
            selectedTab = tabsFilters[i].dataset.term
          }
        }
        filterPosts(
          this.dataset.postType,
          selectedTab,
          selectedOption,
          selectedTags,
          this.dataset.titleLength,
          this.dataset.pagination,
          this.dataset.viewAll,
          this.dataset.maxPosts,
          this.dataset.columns,
          this,
          removeElementsByClass
        )
      })
    })

    // Select taxonomies listeners
    if (this.selectFilter[0]) {
      this.selectFilter[0].addEventListener('change', function (e) {
        selectedOption = optionsFilter[e.target.selectedIndex].dataset.term
        filterPosts(
          optionsFilter[e.target.selectedIndex].dataset.postType,
          selectedTab,
          selectedOption,
          selectedTags,
          optionsFilter[e.target.selectedIndex].dataset.titleLength,
          optionsFilter[e.target.selectedIndex].dataset.pagination,
          optionsFilter[e.target.selectedIndex].dataset.viewAll,
          optionsFilter[e.target.selectedIndex].dataset.maxPosts,
          optionsFilter[e.target.selectedIndex].dataset.columns,
          optionsFilter[e.target.selectedIndex],
          removeElementsByClass
        )
        e.preventDefault()
      })
    }

    // Tags buttons listeners
    Array.prototype.forEach.call(this.tags, function (tag) {
      tag.addEventListener('click', function (e) {
        selectedTags = updateSelectedTags(this.dataset.term, selectedTags)
        updateTagsClass(tags, selectedTags)
        filterPosts(
          this.dataset.postType,
          selectedTab,
          selectedOption,
          selectedTags,
          this.dataset.titleLength,
          this.dataset.pagination,
          this.dataset.viewAll,
          this.dataset.maxPosts,
          this.dataset.columns,
          this,
          removeElementsByClass
        )
        e.preventDefault()
      })
    })
  }

  addSelectedTag (tag, selectedTags) {
    if (tag === 'all') return []

    if (!selectedTags.includes(tag)) {
      selectedTags.push(tag)
    }

    return selectedTags
  }

  updateSelectedTags (tag, selectedTags) {
    if (tag === '') return []

    if (!selectedTags.includes(tag)) {
      selectedTags.push(tag)
      return selectedTags
    } else {
      return selectedTags.filter((item) => item !== tag)
    }
  }

  updateTagsClass (tags, selectedTags) {
    for (let i = 0; i < tags.length; i++) {
      if (selectedTags.includes(tags[i].dataset.term) || (selectedTags.length === 0 && tags[i].dataset.term === '')) {
        tags[i].classList.add('active')
      } else {
        tags[i].classList.remove('active')
      }
    }
  }

  filterPosts (postType, contentCategoryTerm, contentTypeTerm, postTagTerms, titleLength, pagination, viewAll, maxPosts, columns, element, removeElementsByClass) {
    const request = window.ajaxReq()
    let url = encodeURI(scripts_ajax_variables.ajax_url) // eslint-disable-line
    let data = 'action=filter_posts' + '&post-type=' + postType + '&title-length=' + titleLength + '&pagination=' + pagination + '&view-all=' + viewAll + '&max-posts=' + maxPosts + '&columns=' + columns // eslint-disable-line

    if (contentCategoryTerm) {
      data += '&content_category_term=' + contentCategoryTerm
    }

    if (contentTypeTerm) {
      data += '&content_type_term=' + contentTypeTerm
    }

    if (postTagTerms.length > 0) {
      for (let i = 0; i < postTagTerms.length; i++) {
        data += '&post_tag_terms[]=' + postTagTerms[i]
      }
    }

    data += '&nonce=' + scripts_ajax_variables.nonce // eslint-disable-line

    request.open('POST', url, true) // set true for async, false for sync request
    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
    request.send(data) // or null, if no parameters are passed

    // On success append data
    request.onreadystatechange = function () {
      if (request.readyState === 4 && request.status === 200) {
        try {
          const grid = element.closest('.cpt_filter_posts_container')
          const gridCards = grid.getElementsByClassName('cpt_filter_container')[0]

          // Remove current data
          removeElementsByClass(gridCards, 'cpt_filter_item')

          // Append data
          gridCards.innerHTML = request.responseText

          // Reload load more button
          window.pagination = new Pagination()

          // Refresh Lightbox
          refreshFsLightbox() // eslint-disable-line
        } catch (error) {
          console.log(error)
        }
      }
    }
  }

  removeElementsByClass (element, className) {
    var elements = element.getElementsByClassName(className)

    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0])
    }
  }
}

export default Filters
