import FsLightbox from 'fslightbox' // eslint-disable-line

class Cta {
  constructor () {
    this.videoPlayButton = document.getElementsByClassName('js-video-play')[0]
    if (this.videoPlayButton) {
      this.init()
    }
  }

  init () {
    const videoPlayButtons = document.getElementsByClassName('js-video-play')

    refreshFsLightbox() // eslint-disable-line

    Array.prototype.forEach.call(videoPlayButtons, function (videoPlayButton) {
      const lightboxDataAttribute = videoPlayButton.dataset.fslightbox
      // Autoplay popup video
      fsLightboxInstances[lightboxDataAttribute].props.onOpen = function (FsLightbox) { // eslint-disable-line
        function playWhenReady () {
          if (document.querySelectorAll('.fslightbox-container video')[0]) {
            document.querySelectorAll('.fslightbox-container video')[0].play()
          } else {
            setTimeout(playWhenReady, 500)
          }
          return true
        }
        return playWhenReady()
      }
    })
  }
}

export default Cta
