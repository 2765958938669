class Header {
  constructor () {
    this.initHeader()
    this.initMenu()
  }

  initHeader () {
    const header = document.getElementById('header')
    const headerHeight = header.offsetHeight + 60
    let distanceToTop = document.documentElement.scrollTop

    // Menu button event listener
    const mobileMenuToogler = document.getElementById('main-menu-toggler')
    if (mobileMenuToogler) { mobileMenuToogler.addEventListener('click', blockScroll) }

    // Scroll event listener
    window.addEventListener('scroll', throttle(callback, 200))

    if (distanceToTop > 0) {
      header.classList.add('scrolled')
    }

    function blockScroll () {
      const body = document.body
      header.classList.toggle('toggled')
      if (!this.classList.contains('menu-item-has-children')) {
        if (body.classList.contains('u-mobile-block-scroll')) {
          document.body.classList.remove('u-mobile-block-scroll')
        } else {
          document.body.classList.add('u-mobile-block-scroll')
        }
      }
    }

    function throttle (callback, wait = 100) {
      let timer = null

      return function (args) {
        if (timer === null) {
          timer = setTimeout(() => {
            callback.apply(this, args)
            timer = null
          }, wait)
        }
      }
    }

    function callback () {
      const newDistanceToTop = document.documentElement.scrollTop

      if (newDistanceToTop <= headerHeight) {
        header.classList.remove('scrolled', 'scrolled--show', 'fixed')
      } else if (newDistanceToTop < distanceToTop && newDistanceToTop > headerHeight) {
        header.classList.add('scrolled--show')
        header.classList.add('fixed')
      } else {
        header.classList.add('scrolled')
        header.classList.remove('scrolled--show')
      }
      distanceToTop = newDistanceToTop
    }
  }

  initMenu () {
    const inputHeader = document.getElementById('main-menu-toggler')
    const header = document.getElementById('header')
    const itemsHasChildren = document.getElementsByClassName('menu-item-has-children menu-item-level-0')
    const itemsHasParent = document.getElementsByClassName('menu-item-level-1')

    for (let i = 0; i < itemsHasChildren.length; i++) {
      itemsHasChildren[i].addEventListener('click', function () {
        try {
          itemsHasChildren[i].classList.toggle('isOpen')
        } catch {}
      })

      itemsHasChildren[i].addEventListener('mouseenter', function () {
        try {
          header.classList.add('isHover')
        } catch {}
      })

      itemsHasChildren[i].addEventListener('mouseleave', function () {
        try {
          header.classList.remove('isHover')
        } catch {}
      })
    }

    for (let i = 0; i < itemsHasParent.length; i++) {
      itemsHasParent[i].addEventListener('click', function () {
        inputHeader.checked = false
        header.classList.remove('toggled')
      })
    }
  }
}

export default Header
